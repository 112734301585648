import { useEffect, useState } from 'react'

export function useAuth() {
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('userToken'))
  useEffect(() => {
    window.addEventListener('storage', () => {
      const isAuth = !!localStorage.getItem('userToken')
      setIsAuthenticated(isAuth)
    })
    return () => {
      window.removeEventListener('storage', () => {
        const isAuth = !!localStorage.getItem('userToken')
        setIsAuthenticated(isAuth)
      })
    }
  }, [])

  return [isAuthenticated, setIsAuthenticated]
}
