export const allRoutes = {
  home: '/',
  auth: '/auth',
  about: '/about',
  contact: '/contact',
  faq: '/faq',
  explore: '/explore',
  chat: '/chat',
  chatByCategory: (categoryId) => `/chat/${categoryId}`,
  profile: '/profile',
  editProfile: (type) => `/profile/${type}`,
  privacyPolicy: '/privacy-policy',
  singleChat: '/details',
  terms: '/terms'
}
