import React, { useContext } from 'react'
import PhoneInput from 'react-phone-input-2'
import { Controller } from 'react-hook-form'
import { Box, FormControl, FormHelperText } from '@mui/material'
import 'react-phone-input-2/lib/bootstrap.css'
import { validationErrors } from 'shared/constants/ValidationErrors'
import { LanguageContext } from 'context/languageContext'

const Phone = ({ control, name, errors, disabled, country }) => {
  const { locale } = useContext(LanguageContext)

  return (
    <Box className="phone-input" dir={locale === 'ar' ? 'ltr' : 'ltr'}>
      <FormControl>
        <Controller
          name={name}
          control={control}
          rules={{
            required: {
              value: true,
              message: validationErrors.required
            },
            minLength: {
              value: 5,
              message: validationErrors.validPhone
            }
          }}
          render={({ field: { onChange, value } }) => (
            <PhoneInput
              enableLongNumbers={true}
              enableSearch={false}
              name={name}
              disabled={disabled}
              disableDropdown={true}
              country={country}
              value={value}
              specialLabel=""
              onChange={(e) => {
                onChange(e)
              }}
              inputProps={{
                id: name,
                name
              }}
            />
          )}
        />
        {errors?.[name]?.message && (
          <FormHelperText style={{ textAlign: locale === 'ar' ? 'right' : 'left' }} error>
            {errors?.[name]?.message}
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  )
}

export default Phone
