import React, { useEffect, useRef, useState } from 'react'
import { Box, ButtonGroup, CircularProgress, IconButton, Typography } from '@mui/material'
import { useIntl } from 'react-intl'
import { useMutation } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

import { iconCheck, iconClose, iconDelete, iconEdit } from 'assets/images'
import { CHAT_TITLE_ACTION_TYPES } from 'shared/constants'
import axiosInstance from 'shared/libs/axios'
import { apiPaths } from 'shared/constants/apiPaths'

const ChatItem = ({ title, id, refetchGetMessages, handleClick, activeId }) => {
  const { categoryId } = useParams()
  const [actionType, setActionType] = useState(false)
  const contentEditableRef = useRef(null)
  const labels = {
    delete: useIntl().formatMessage({ id: 'delete' })
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (contentEditableRef.current && !contentEditableRef.current.contains(event.target)) {
        contentEditableRef.current.setAttribute('contenteditable', 'false')
        setActionType(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [contentEditableRef])

  const chatTitleUpdate = async (data) => {
    return await axiosInstance.put(apiPaths.updateMessage(data.messageId), { question: data.question, category_id: data.categoryId })
  }

  const chatTitleUpdateMutation = useMutation((data) => chatTitleUpdate(data), {
    onSuccess: () => {
      refetchGetMessages()
    }
  })

  const chatTitleDelete = async (id) => {
    await axiosInstance.delete(apiPaths.updateMessage(id))
  }

  const chatTitleDeleteMutation = useMutation((id) => chatTitleDelete(id), {
    onSuccess: () => {
      refetchGetMessages()
    }
  })

  const isActionLoading = chatTitleUpdateMutation.isLoading || chatTitleDeleteMutation.isLoading
  const isEditable = contentEditableRef?.current?.getAttribute('contenteditable') === 'true'

  const handleChatTitleAction = (type) => () => {
    if (!type) {
      setActionType(false)
    } else {
      setActionType(type)
      if (type === CHAT_TITLE_ACTION_TYPES.EDIT) {
        contentEditableRef.current.setAttribute('contenteditable', 'true')
        contentEditableRef.current.focus()
        const range = document.createRange()
        const selection = window.getSelection()
        range.selectNodeContents(contentEditableRef.current)
        range.collapse(false)
        selection.removeAllRanges()
        selection.addRange(range)
      }
      if (type === CHAT_TITLE_ACTION_TYPES.EDIT_SUBMIT) {
        setActionType(false)
        onChatTitleChangeSubmit({
          messageId: id,
          question: contentEditableRef.current.innerText,
          categoryId
        })
      }
      if (type === CHAT_TITLE_ACTION_TYPES.DELETE_SUBMIT) {
        chatTitleDeleteMutation.mutate(id)
      }
    }
  }

  const onChatTitleChangeSubmit = (value) => {
    chatTitleUpdateMutation.mutate(value)
  }

  const onClickHandler = (e) => {
    handleClick(id)
  }

  return (
    <Box
      onClick={() => onClickHandler()}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      bgcolor={activeId === id ? '#EFF2FC' : ''}
      position="relative"
      sx={{
        cursor: 'pointer',
        padding: '13px 18px',
        transition: '0.3s ease',
        '&:hover': {
          background: '#EFF2FC',
          '& .MuiButtonGroup-root': {
            background: 'linear-gradient(270.12deg, #EFF2FC 45.11%, #EFF2FC 59.68%, #EFF2FC 136.64%)'
          }
        },
        '& .MuiTypography-body1': {
          color: '#2F2F2F',
          fontSize: '16px',
          fontWeight: '400',
          width: isEditable ? '100%' : '81%',
          wordBreak: 'break-all',
          textOverflow: isEditable ? 'none' : 'ellipsis',
          marginRight: isEditable ? '10px' : '0',
          overflow: 'hidden',
          height: '22px',
          whiteSpace: 'nowrap'
        },
        '& button': {
          padding: '0',
          '&:hover': {
            background: 'transparent'
          },
          '& img': {
            width: '15px',
            height: 'auto'
          }
        },
        '& .MuiButtonGroup-root': {
          gap: '10px',
          // position: 'absolute',
          // paddingLeft: '10px',
          // right: '10px',
          background: 'linear-gradient(270.12deg, #EFF2FC 45.11%, #EFF2FC 59.68%, #EFF2FC 136.64%)',
          height: '100%',
          transition: '0.3s ease'
        }
      }}
    >
      <Typography
        variant="body1"
        ref={contentEditableRef}
        suppressContentEditableWarning={true}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault()
            contentEditableRef.current.setAttribute('contenteditable', 'false')
            setActionType(false)
            onChatTitleChangeSubmit({
              messageId: id,
              question: e.target.innerText,
              categoryId
            })
          }
        }}
      >
        {actionType === CHAT_TITLE_ACTION_TYPES.DELETE ? `${labels.delete} "${title}"?` : `${title}`}
      </Typography>

      {activeId === id && (
        <ButtonGroup size="small" aria-label="small button group">
          {isActionLoading && <CircularProgress size="15px" sx={{ color: '#0B41FF' }} />}
          {!actionType && !isActionLoading && (
            <>
              <IconButton onClick={handleChatTitleAction(CHAT_TITLE_ACTION_TYPES.EDIT)}>
                <img src={iconEdit} alt="edit" />
              </IconButton>
              <IconButton onClick={handleChatTitleAction(CHAT_TITLE_ACTION_TYPES.DELETE)}>
                <img src={iconDelete} alt="delete" />
              </IconButton>
            </>
          )}
          {actionType && !isActionLoading && (
            <>
              <IconButton
                onClick={
                  actionType === CHAT_TITLE_ACTION_TYPES.EDIT
                    ? handleChatTitleAction(CHAT_TITLE_ACTION_TYPES.EDIT_SUBMIT)
                    : handleChatTitleAction(CHAT_TITLE_ACTION_TYPES.DELETE_SUBMIT)
                }
                onMouseDown={(e) => e.stopPropagation()}
              >
                <img src={iconCheck} alt="confirm" />
              </IconButton>
              <IconButton onClick={handleChatTitleAction(false)} onMouseDown={(e) => e.stopPropagation()}>
                <img src={iconClose} alt="cancel" />
              </IconButton>
            </>
          )}
        </ButtonGroup>
      )}
    </Box>
  )
}

export default ChatItem
